.categories_section {
  /* margin-top: 9.5rem; */
}

.categories_header {
  text-align: center;
  font-family: "cairo";
  overflow: hidden;
  font-size: 2rem;
  color: white;
}

/* .categories_header::before,
.categories_header::after {
  background-color: #e4e4e4;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.categories_header::before {
  right: 0.5em;
  margin-left: -50%;
}

.categories_header::after {
  left: 0.5em;
  margin-right: -50%;
} */

.category {
  cursor: pointer;
  background-color: #222222;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0 6px 5px 0 rgba(217, 28, 28, 0.1), 
              0 6px 5px 0 rgba(217, 28, 28, 0.3);
}

.category:hover {
  .category_img {
    transform: scale(1.1);
  }
  background-color: #D91C1C;
}
.category_img {
  margin: auto;
  display: block;
  transition: all 0.3s;
  width: 200px;
  height: 200px;
}
.category_name {
  text-align: center;
  font-family: "cairo";
  font-size: 1.4rem;
  font-weight: bold;
  color: white;
}

@media only screen and (max-width: 767px) {
  .category_img {
    width: 100px;
    height: 100px;
  }
  .categories_section {
    margin-top: 2rem;
  }
  .categories_header{
    font-size: 1.5rem;
  }
}
