.primaryButton{
    width: 100%;
    cursor: pointer;
    /* height: 2.7rem; */
    background-color: #D91C1C;
    color: white;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'cairo';
    transition: all 0.2s;
    font-weight: bold;
    padding: 0.5rem;
}

.primaryButton:hover{
    transform: translateY(-5px);
}

.red{
    background-color: red;
}