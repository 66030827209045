.checkOut-content {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 4rem;
  direction: rtl;
  overflow: hidden;
  margin-bottom: 15rem;
  padding-top: 7rem;

}

.receipt-details {
  font-family: "cairo";
  color: white
}

input{
  color: black
}

select{
  color: black
}
textarea{
  color: black
}
.receipt-details_text{
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.checkOutDetails-container {
  display: grid;
  grid-template-columns: 50% 50%;
  direction: rtl;
}

.checkOut-input {
  padding: 0.8rem;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-family: "cairo";
}

.receipt {
  padding-top:  1rem;
  padding-right: 3rem;
  padding-left: 3rem;
  font-family: 'cairo';
  background-color: #272727;
  margin-right: 2rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


.receipt_text{
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.receiptDetails_content{
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.receiptDetails_content_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 1rem;
}

.receiptDetails_Text{
    font-weight: bold;
    font-size: 0.9rem;

}

.marginTop{
    margin-top: 1rem;
}

@media only screen and (max-width: 767px) {

  .checkOut-content {
    display: grid;
    grid-template-columns: 100%;
    padding: 1rem;
    margin-top: 7rem;
  }

  .receipt {
    margin-top: 1rem;
    padding-bottom: 1rem;
    margin-right: 0;
  }
}