.card{
    border: 1.5px solid #E4E4E4;
    border-radius: 10px;
    padding: 2rem;
    direction: rtl;
    margin-right: 2rem;
    height: 480px;
    width: 100%; 
    max-width: 240px; 
    
    /* box-shadow: 1px 1px 1px black; */
    /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.card_img{
    width: 100%;
    height: 200px;
    cursor: pointer;
}

.card_productName{
    height: 40px;
    text-align: right;
    margin-top: 1rem;
    font-size: 1rem;
    font-family: 'cairo';
    cursor: pointer;
    font-weight: bold;
    overflow: hidden ;
    text-overflow: ellipsis ;
    white-space: nowrap ; 
    -webkit-box-orient: vertical ; 
    width: 100%;
}

.card_productPrice{
    text-align: right;
    margin-top: 2rem;
    font-size: 1.2rem;
    font-family: 'cairo';
    color: #03045e;
    direction: ltr;
    font-weight: bold;
}

.card_productPrice_beforeDiscount{
    text-align: right;
    font-size: 0.9rem;
    font-family: 'cairo';
    color: #999;
    text-decoration: line-through;
    direction: ltr;
 
}

.button_container{
    margin-top: 2rem;
}



@media only screen and (max-width: 767px) {
    .card{
        max-width: 125px; 
        height: 300px;
        margin-bottom: 2rem;
    }
    .card_img{
        height: 130px;
    }
}