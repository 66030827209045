.reviews_section{
    margin-top: 2.5rem;
}

.reviews_header{
    text-align: center;
    font-family: 'cairo';
    overflow: hidden;
    font-size: 2rem;
    color: white
}


/* .reviews_header::before,
.reviews_header::after {
  background-color: #E4E4E4;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.reviews_header::before {
    right: 0.5em;
    margin-left: -50%;
  }
  
  .reviews_header::after {
    left: 0.5em;
    margin-right: -50%;
  } */

  .reviews_container{
    padding: 1rem;
  }

  @media (max-width: 480px) {
    .reviews_header{
      font-size: 1.5rem;
    }
  }