.greeting-section {
  background-color: black; /* Light gray background */
  padding: 10px 20px;
  text-align: center;
  font-size: 1vw; /* Responsive font size based on viewport width */
  font-weight: bold;
  color: white; /* Dark text color */
  border-bottom: 1px solid #ddd; /* Optional border */
  margin-top: 4rem;
  animation: moveText 30s linear infinite; /* Infinite animation */
}

.greeting-section p {
  margin: 0;
}

@media (max-width: 768px) {
  .greeting-section {
    font-size: 4vw; /* Adjust font size for tablets and smaller devices */
  }
}

@media (max-width: 480px) {
  .greeting-section {
    font-size: 2.5vw; /* Adjust font size for mobile devices */
  }
}

@keyframes moveText {
  0% {
    transform: translateX(100%); /* Start from right */
  }
  100% {
    transform: translateX(-100%); /* End at left */
  }
}
