.image-zoom-container {
  position: relative;
  width: 600px; /* Adjust as needed */
  height: 400px; /* Adjust as needed */
}

.zoomed-image {
  pointer-events: none;
}

.productDetails_productImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}
