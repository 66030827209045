.box-container {
  display: flex;
  justify-content: center;/* Vertically center the box */
  
  padding: 2rem; /* Full viewport height */
}

.plicy-head{
  text-align: center;
  font-family: 'cairo';
  font-size: 2rem;
  text-decoration: underline;
  color: blue;
  margin-bottom: 1rem;
}
.policies-container {
  width: 80%; /* Adjust width as needed */
  background-color: #fff;
  border-radius: 10px;
  padding: 4rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  font-family: "cairo";
  text-align: right;
  direction: rtl;
}

.policy-header {
  font-size: 1rem;
  font-weight: bold;
  color: red;
}

ul li {
  margin-top: 1rem;
}
ol li {
  margin-top: 1rem;
}

