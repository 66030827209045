.secondaryButton {
  cursor: pointer;
  /* height: 2.7rem; */
  background-color: #D91C1C;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  font-family: "cairo";
  transition: all 0.2s;
  font-weight: bold;
  padding: 0.5rem;
  width:50%
}

.secondaryButton:hover {
  transform: translateY(-2px);
}

.red {
  background-color: red;
}
