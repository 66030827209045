.header_container {
  /* display: flex;
  flex-direction: row;
  gap: 1vw;
  overflow: hidden; */
 padding-top: 4rem;
}

.carousel_container {
   width: 100%; 
  height: 100%; 
}

.headerCarousel_img{
    width: 100%;
    height: 100%;
}

/* .header-rightSide {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.header-rightSide_1{
    height: 100%;
}
.header-rightSide_2{
    height: 100%;
} */
@media only screen and (max-width: 767px) {
  .header_container {
    margin-top: 0;
    padding-top: 0;
  }
}