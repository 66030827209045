.cartButton{
    width: 100%;
    cursor: pointer;
    height: 2.7rem;
    background-color: #00296b;
    color: white;
    border-radius: 5px;
    font-size: 1.2rem;
    font-family: 'cairo';
    transition: all 0.2s;
    font-weight: bold;
}