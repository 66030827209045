/* Entire banner section */
.banner-section {
  width: 100%;
  background-color: #000;
  color: #fff;
  /* margin-top: 4rem; */
  font-family: "Cairo";
}

/* Banner content */
.banner-content {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-title {
  font-size: 36px;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

/* Search Bar Section */
.search-bar {
  background-color: #fff;
  border-radius: 8px;
  margin: -45px auto 20px;
  max-width: 80%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 10;
  padding: 10px;
}

/* Flex container for inputs and buttons */
.search-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap; /* Keep items in a single row */
}

.category-dropdown {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px; /* Reduce font size */
  color: #000;
}

.category-dropdown img {
  width: 20px; /* Adjust size for better fit */
  height: 20px;
}

.dropdown-select2 {
  border: none;
  border-radius: 5px;
  font-size: 14px; /* Smaller font */
  color: #000;
  cursor: pointer;
  max-width: 120px; /* Limit dropdown width */
  padding: 5px;
}

.dropdown-select2:focus {
  outline: none;
  border: none;
}

.dropdown-select:focus {
  outline: none;
  border: 1px solid #ccc;
}

.dropdown-select option {
  font-size: 16px;
  color: #000;
}

.dropdown-icon {
  margin-left: 5px;
  font-size: 12px;
}

/* Search input field */
.search-input {
  flex-grow: 1;
  padding: 8px; /* Reduce padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px; /* Smaller font */
}

.search-input:focus {
  outline: none;
  border: none;
}

/* Search button */
.banner-search-button {
  background-color: #e60000;
  color: white;
  padding: 8px 20px; /* Smaller padding */
  border: none;
  border-radius: 5px;
  font-size: 14px; /* Smaller font */
  cursor: pointer;
}


/* Responsive Design */
@media (max-width: 768px) {
  .banner-content {
    height: 300px;
  }

  .banner-title {
    font-size: 24px;
  }

  .search-bar {
    max-width: 100%;
    margin: -40px auto 20px;
    padding: 8px;
  }

  .search-container {
    flex-wrap: nowrap; /* Ensure side-by-side layout */
  }
  .category-dropdown {
    font-size: 12px; /* Even smaller font on mobile */
  }

  .search-input {
    padding: 5px; /* Further reduce padding */
    font-size: 12px;
  }

  .banner-search-button {
    padding: 5px 15px;
    font-size: 12px;
  }

  .search-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .search-bar {
    margin: -30px auto 10px;
  }

  .category-dropdown {
    font-size: 10px; /* Minimized font */
  }

  .search-input {
    font-size: 10px;
    padding: 4px;
  }

  .banner-search-button {
    padding: 4px 10px;
    font-size: 10px;
  }
  
  .banner-content {
    height: 200px;
  }

  .banner-title {
    font-size: 18px;
  }
}
