.search-page {
  padding-top: 8rem;
  
}

.search_productsCard_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  
}

.search_productsCard_container_mobile {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
