.category_page_content {
  display: grid;
  grid-template-columns: 85% 15%;
  padding-top: 10rem;
  background-color: #1b1b1b;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-bottom: 5rem;
}
.category_page__header {
  text-align: center;
  font-family: "cairo";
  color: white;
  font-size: 1.5rem;
}

.categoryPage_right-side {
  display: flex;
  flex-direction: column;
  row-gap: 1vw;
}

.categoryPage_left-side {
  padding-right: 4rem;
}

.rightSide_header {
  text-align: right;
  font-family: "cairo";
  color: white;
}

.rightSide_category {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: white;
}

.rightSide_category-number {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  padding: 4px;
  font-family: "cairo";
  font-weight: bold;
  font-size: 0.8rem;
  transition: all 0.2s ease-out;
}
.rightSide_category-name {
  font-family: "cairo";
  font-size: 1rem;
  font-weight: bold;
  color: white;
}

.rightSide_category:hover {
  .rightSide_category-number {
    background-color: #d91c1c;
    color: white;
  }
}

.leftSide_paragraph {
  font-family: "cairo";
  text-align: right;
  color: #77778d;
}

.productsCard_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 4rem;
  row-gap: 1vw;
}

.showMoreButton_container {
  margin-top: 2rem;
  width: 100%;
  text-align: center;
}

.arrow {
  font-size: 1.2rem; /* Arrow size */
  font-weight: bold; /* Arrow weight */
  line-height: 1; /* Align arrow with text */
}
@media only screen and (max-width: 767px) {
  .category_page_content{
    margin-top: 4rem;
  }
  .categoryPage_right-side {
    display: none;
  }

  .categoryPage_left-side {
    padding-right: 0;
  }
  .category_page_content {
    display: grid;
    grid-template-columns: 100%;
    padding: 1rem;
    padding-top: 2rem;
  }

  .productsCard_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
