.newProducts_section{
    margin-top: 4rem;
}

.newProducts_header{
    text-align: center;
    font-family: 'cairo';
    overflow: hidden;
    font-size: 2rem;
    color: white
}


/* .newProducts_header::before,
.newProducts_header::after {
  background-color: #E4E4E4;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.newProducts_header::before {
  right: 0.5em;
  margin-left: -50%;
}

.newProducts_header::after {
  left: 0.5em;
  margin-right: -50%;
} */

@media (max-width: 480px) {
  .newProducts_header{
    font-size: 1.5rem;
  }
}