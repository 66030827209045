.review-card {
  border-right: 1.5px solid grey;
  border-bottom: 1.5px solid grey;
  /* border-radius: 10px; */
  /* padding: 3rem 2rem; */
  padding: 2rem;

  /* overflow: hidden; */
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
  display: flex;
  flex-direction: column;
  /* margin-right: 1rem; */
  justify-content: space-between;
  box-sizing: border-box;
}

.rating {
  display: flex;
  justify-content: flex-end; 
}

.customer-review {
  display: block;
  font-family: "cairo";
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflow text */
  display: -webkit-box;
  -webkit-line-clamp: 4;  /* Number of lines to show */
  -webkit-box-orient: vertical;
  white-space: normal; /* Allow text to wrap to the next line */
  color: grey;
  text-align: right;
  
}

.customer-name {
  margin-top: 1rem;
  text-align: right;
  color: #949494;
  font-family: "cairo";
  color: white
}
