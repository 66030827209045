.footer {
  background-color: black;
  color: white;
  text-align: right;
  padding-bottom: 2rem;
}

.footer_section {
  display: flex;
  justify-content: space-around;

  /* margin-left: 20rem;
  margin-right: 20rem; */
  padding-bottom: 6rem;
  padding-top: 4rem;
  /* margin-top: 4rem; */
  direction: rtl;
  font-size: 1rem;

  border-bottom: 1px solid grey;
}

.footer_section-column {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  font-family: "cairo";
}

.help {
  font-weight: bold;
  font-size: 1rem;
}

.footer_row {
  display: flex;
  align-items: center;
}

.phone-number {
  /* font-weight: bold; */
  margin-right: 1rem;
}

.address {
  /* font-weight: bold; */
  margin-right: 1rem;
  font-size: 1rem;
}

.rights {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.copy-rights {
  font-family: "cairo";
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
}

.rights-right {
  display: flex;
  gap: 1vw;
  color: white;
  font-family: "cairo";
}
.footer-logo {
  /* width: 270px;
  height: 100px; */
}
.footer__text {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .footer-logo_container {
    width: 100%;
  }
  /* Your mobile styles go here */
  .footer_section {
    margin-right: 0;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 3rem;
  }
  .footer__text {
    width: 100%;
  }
  .footer_section-column {
    width: 50%;
  }

  .footer_row {
    direction: ltr;
  }

  .footer-logo {
    width: 100%;
  }

  .phone-number {
    /* font-weight: bold; */
    margin-left: 1rem;
  }

  .address {
    /* font-weight: bold; */
    margin-left: 1rem;
    font-size: 1rem;
  }
  .rights {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rights-right{
    gap:2vw;
    order: 2;
  }
}
