.productDetials-content {
  display: grid;
  grid-template-columns: 50% 50%;
  font-family: "cairo";
  margin-bottom: 15rem;
  padding-top: 12rem;
}

.product-content {
  text-align: right;
  margin-right: 2rem;
}

.productName {
  direction: rtl;
  font-size: 2rem;
  color: white;
}

.productPrice {
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
  text-align: left;
}

.left-column {
  width: 80%;
}

.product_direction {
  margin-top: 1rem;
  font-family: "cairo";
}

.matrial-type {
  margin-top: 1rem;
  font-family: "cairo";
}

.direction-text {
  font-weight: bold;
}

.matrial-text {
  font-weight: bold;
}

.directions_container {
  direction: rtl;
  display: flex;
  flex-direction: row;
  gap: 1vw;
  margin-top: 1rem;
}

.direction {
  font-family: "cairo";
  border-radius: 10px;
  cursor: pointer;
  padding: 0.5rem;
  border: 1px solid black;
}

.direction:hover {
  background-color: #03045e;
  color: white;
}

.selected {
  background-color: #03045e;
  color: white;
}

.product-color {
  margin-top: 2rem;
  font-weight: "lemonada";
}
.color-text {
  font-weight: bold;
}

.color-container {
  /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1vw;
    direction: rtl;
    margin-top: 1rem; */
  display: grid;
  grid-template-columns: 1fr;
}

.color {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  /* width: 30rem;
  height: 30rem; */
  margin-top: 1rem;
}

.add-cart {
  display: flex;
  flex-direction: row;
  gap: 1vw;
  direction: rtl;
  margin-top: 2rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #e5e5e5;
}

.product-characteristics{
  margin-top: 2rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #e5e5e5;
}

.product-quote{
  margin-top: 2rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #e5e5e5;
  direction: rtl;
  color: white
}

.product-quote li {
  font-size: 1.2rem;
}


.increase-decrease-container {
  display: flex;
}

.increase-decrease-button {
  padding: 0.8rem;
  background-color: white;
  border-color: #e5e5e5;
}

.description-container {
  font-family: "cairo";
  margin-top: 1rem;
}

.description-text {
  font-size: 1rem;
  font-weight: bold;
}

.product-image {
  overflow: hidden;
}
.productDetails_productImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.delivery-container {
  border-top: 1px solid #e5e5e5;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 999;
}

.modal-photo {
  max-width: 80vw;
  max-height: 80vh;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.blur-background {
  filter: blur(5px); /* Adjust the blur amount as needed */
}

.desc_button {
  background-color: #272727;
  color: white;
  border-radius: 10px;
  width: 100%;
  /* border: 1px solid black; */
  padding: 0.5rem;
  margin-top: 10px;
  transition: all 0.2s;
}

.desc_button:hover {
  background-color: #03045e;
  color: white;
}

.right-column {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
@media only screen and (max-width: 767px) {
  .productDetails-page {
    margin-top: 4.5rem;
  }
  .productDetials-content {
    display: grid;
    grid-template-columns: 100%;
    padding: 1rem;
    padding-top: 1rem;
  }

  .left-column {
    order: 2;
  }

  .out_of_stock_line {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 2px solid red;
    border-left: 2px solid transparent;
    transform: rotate(45deg);
    z-index: 1;
    pointer-events: none;
  }
  
  .right-column {
    order: 1;
  }
  .product-content {
    margin-right: 0;
    margin-top: 1rem;
    width: 100%;
  }
  .productPrice {
    font-size: 2rem;
  }
  .productName {
    font-size: 2.5rem;
  }
  .product_companyName{
    font-size: 1.3rem
    
  }

  .productDetails_productImage {
    height: 100%;
  }
  .product-quote li{
    font-size: 1.3rem;
  }
}
