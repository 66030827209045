.productCard__ {
  width: "100%";
}

@media screen and (max-width: 768px) {
  .productsCard_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .productCard__ {
    margin-top: 1rem;
    width: 48%;
  }
}
