.products_section__categories {
  width: 100%;
  display: flex;
  gap: 4vw;
  border-bottom: 3px solid #e4e4e4;
  direction: rtl;
  margin-top: 6rem;
  overflow-x: auto; /* Enables horizontal scrolling if categories overflow */
  white-space: nowrap; /* Ensures all categories are in one line */
  padding-bottom: 1rem;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on mobile */
}

.products_section__categoryName {
  font-size: 1.2rem;
  font-family: "cairo";
  cursor: pointer;
  transition: all 0.2s;
  padding-bottom: 1rem;
  font-weight: bold;
  color: white;
  background-color: #272727;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  display: inline-block; /* Ensures inline behavior for horizontal scroll */
}

.products_section__categoryName:hover {
  background-color: #D91C1C;
}

.productsCards_container {
  /* margin-top: 2rem; */
}

/* Selected category styles */
.selectedCategory {
  background-color: #D91C1C;
}

/* Media queries for further refinements */

/* Tablet and smaller */
@media only screen and (max-width: 1024px) {
  .products_section__categories {
    gap: 2vw; /* Reduce spacing on smaller screens */
    margin-top: 4rem;
  }

  .products_section__categoryName {
    font-size: 1rem; /* Adjust font size */
  }

  .productsCards_container {
    margin-top: 1.5rem;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .products_section__categories {
    gap: 3.5vw; /* Further reduce the gap for mobile */
    margin-top: 3rem;
  }

  .products_section__categoryName {
    font-size: 0.9rem; /* Reduce font size for mobile */
    padding-bottom: 0.5rem;
  }

  .productsCards_container {
    /* margin-top: 1rem; */
  }
}

@media only screen and (max-width: 480px) {
  .products_section__categoryName {
    font-size: 0.85rem;
  }
}
