/* General Navbar Styles */
.navbar {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  font-family: "cairo";
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo .logo {
  height: 50px;
}

.navbar-links {
  display: flex;
  gap: 20px;
}

.navbar-links ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.navbar-links a:hover {
  color: #e60000;
}

/* Shop Now Button */
.shop-now-button {
  background-color: #e60000;
  color: #fff;
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Cart Icon */
.cart-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 10px;
}

/* Hamburger Menu Button (Mobile View Only) */
.menu-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 2rem;
  display: none;
}

.language-toggle {
  display: flex;
  align-items: center;
  gap: 5px;
}

.language-toggle2 {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Language Button Styles */
.language-button {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

/* Active Language Button */
.language-button.active {
  background-color: #fff;
  color: #e60000;
  font-weight: bold;
}

/* Hover Effect */
.language-button:hover {
  opacity: 0.9;
}

.navbar-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 5px;
  }
  .test {
    width: 50%;
  }

  .navbar-logo {
    width: auto; /* Automatically adjusts width based on content */
    max-width: 100%; /* Ensures it doesn't exceed 50% of the container */
    margin: 0 auto; /* Centers the logo if needed */
    display: flex; /* Flexbox for better alignment */
    justify-content: center; /* Center the logo horizontally */
  }

  .navbar-logo .logo {
    width: 100%; /* Full width within the parent container */
    height: auto; /* Maintains aspect ratio */
  }

  .cart-icon {
    width: 150px;
    height: 150px;
  }
  .navbar-links {
    display: none;
    flex-direction: column;
    gap: 15px;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #000;
    padding: 10px;
    border-radius: 5px;
  }

  .language-toggle {
    display: none;
  }

  .navbar-links.active {
    display: flex;
  }

  .shop-now-button {
    display: none;
  }

  .menu-button {
    display: block;
  }

  .navbar-actions {
    display: flex;
    align-items: center;
  }
}

.signout {
  cursor: pointer;
}

.signout:hover {
  color: #e60000;
}

@media (max-width: 768px) {
  .language-button {
    font-size: 12px;
    padding: 5px 10px;
  }
}
