.companies_section {
  margin-top: 2.5rem;
  padding: 0 1rem; /* Add padding for mobile screens */
  background-color: #1b1b1b;
  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 6rem;
  padding-bottom: 3rem;
}

.companies_header {
  text-align: center;
  font-family: "cairo";
  overflow: hidden;
  font-size: 2rem; /* Adjust font size for responsiveness */
  margin-bottom: 1.5rem;
  color: white;
}

.company-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .company-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;
  }

  .company-card {
    border-radius: 6px;
  }

  .text {
    font-size: 1.25rem; /* Adjust text size for smaller screens */
  }
}

@media (max-width: 480px) {
  .companies_section {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .company-grid {
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    width: 100%;
  }

  .companies_header {
    font-size: 1.5rem; /* Adjust header font size for mobile */
  }

  .text {
    font-size: 1rem; /* Further reduce text size on mobile */
  }

  .company-card {
    border-radius: 4px;
  }
}

.company-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}

.company-image {
  position: relative;
}

.company-image img {
  width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: center;
  padding: 10px;
}

.company-card:hover .overlay {
  opacity: 1;
}

.text {
  font-size: 1.5rem;
  font-weight: bold;
}
