.nav-bar_container {
  direction: rtl;
  /* display: flex; */
  /* flex-direction: row; */
  /* gap: 1vw; */
  /* align-items: center; */
  z-index: 100;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 4px 4px 4px #bebdbd;
  /* padding-left: 15rem; */
  /* padding-right: 15rem; */
  background-color: rgba(255, 255, 255, 0.93); 
  transition: background-color 0.5s ease;
  width: 100%;
  /* justify-content: space-between; */
}

.nav-bar_content{
  display: flex;
  flex-direction: row;
  gap: 1vw;
  align-items: center;
  justify-content: center;
}

.logo-container {
}

.logo {
  width: 270px;
  height: 130px;
  cursor: pointer;
}

.nav-bar_items {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  align-items: center;
  font-family: "cairo";
}

.nav-bar_item {
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 0.3rem;
  cursor: pointer;
  transition: all 0.2s;
  color: #0077b6;
}

.nav-bar_item:hover {
  color: #03045e;
  border-bottom: 0.2rem solid #03045e;
}

.input {
  padding: 15px 15px 15px 233px;
  font-family: 'cairo';
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #807777;
  font-size: 14px;
}

.input:focus {
  outline: none;
}

.search-inputs_container {
  display: flex;
}

.search-button {
  /* float: left; */
  /* width: 20%; */
  width: 4rem;
  padding: 10px;
  background: #2196f3;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  /* border-left: none; Prevent double borders */
  cursor: pointer;
  border-radius: 2px;
}

.search-button:hover {
  background: #0b7dda;
}

.drawer-content {
  /* margin-top: 20px; */
  padding-left: 2rem;
  padding-right: 2rem;
  direction: rtl;
  position: relative;
}

.product-item_container {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'cairo';
}

.product-item {
  display: flex;
  cursor: pointer;
  /* margin-bottom: 20px; */
}

/* .product-item:hover {
  background-color: #f7f7f7;
} */

.product-item img {
  width: 100px;
  height: 130px;
  margin-left: 10px;
}

.product-name {
  font-family: 'cairo';
  font-size: 1rem;
  font-weight: bold;
}

.corner-direction {
  font-family: 'cairo';
  font-size: 1rem;
  font-weight: bold;
}

.matrial-type2{
  font-family: 'cairo';
  font-size: 1rem;
  font-weight: bold;
}

.color-number{
  font-family: 'cairo';
  font-size: 1rem;
  font-weight: bold;
}

.product-price {
  font-family: 'cairo';
  font-size: 1rem;
  font-weight: bold;
}

.cart-header {
  font-family: 'cairo';
  font-size: 1.2rem;
  text-align: right;
}

.total-section {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid grey;
  padding-top: 1rem;
  /* width: 100%; */
  /* padding-left: 2rem;
    padding-right: 2rem; */
  /* overflow: hidden; */
}

.total {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-family: 'cairo';
  font-weight: bold;
  margin-bottom: 1rem;
}
.total-text {
  font-size: 1.3rem;
}

.total-price {
  font-size: 1.3rem;
}

.nav-barMobile_container {
  
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  display: none;
}

.divider2Content{
  padding: 2rem;
}

.dividerItem_content{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.divider_text{
  margin-bottom: 0.4rem;
  font-size: 1.3rem;
  font-family: 'cairo';
}



.language-switcher {
  position: relative;
  display: inline-block;
  width: calc(64 * 2);
  height: "64px";
  -webkit-transform: translateY(40%);
  -moz-transform: translateY(40%);
  -ms-transform: translateY(40%);
  -o-transform: translateY(40%);
  transform: translateY(40%);
  -webkit-transition: transform 0.17s cubic-bezier(0.26, 0.48, 0.08, 0.9);
  -moz-transition: transform 0.17s cubic-bezier(0.26, 0.48, 0.08, 0.9);
  -ms-transition: transform 0.17s cubic-bezier(0.26, 0.48, 0.08, 0.9);
  -o-transition: transform 0.17s cubic-bezier(0.26, 0.48, 0.08, 0.9);
  transition: transform 0.17s cubic-bezier(0.26, 0.48, 0.08, 0.9);

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .select-fr,
  .select-en {
    position: absolute;
    font-size: 64/ 2.5;
    top:  64 / 4;
    color: #fff;
    mix-blend-mode: difference;    
  }
  .select-fr {
    left: 64 / 3.5;
  }
  .select-en {
    right: 64 / 4;
  }
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:  #1e3470;;
  box-shadow: 0 3px 64px rgba( #1e3470, .1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: "64px";
  width: "64px";
  left: 0;
  bottom: 0;
  background-color: white;
    box-shadow: 0 3px 64px rgba(#1e3470, .16);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1e3470;
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX("64px");
  -ms-transform: translateX("64px");
  transform: translateX("64px");
}

/* Rounded sliders */
.slider.round {
  border-radius: "64px";
}

.slider.round:before {
  border-radius: 50%;
}


@media only screen and (max-width: 767px) {
  .nav-bar_container {
    display: none;
  }
  .nav-barMobile_container {
    display: flex;
  }

  .product-item img {
    width: 55px;
    height: 55px;
  }
  

}
